
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DashboardCategory, SubCategory, SubSubCategory } from '@/features/om27/types/om27.types';
import IconMenu from '@/components/gsk-components/menu/IconMenu.vue';
import { MenuOption } from '@/components/gsk-components/menu/menu.types';
import { RpaOpsModule } from '@/features/om27/store/om27.module';
import { getQuery, updateQuery } from '@/utils/routing';

@Component({
  components: {
    IconMenu,
  },
})
export default class MainFilter extends Vue {
  @Prop({ required: true, type: Array }) categories!: DashboardCategory[];

  get category(): string {
    return getQuery(this, 'category', { toArray: false, toNumber: false }) ?? '';
  }
  get categoryMenu(): MenuOption<SubCategory[]>[] {
    return RpaOpsModule.categories.map(cat => {
      return {
        key: cat.name,
        text: cat.name,
        extra: cat.subcategories,
      };
    });
  }

  handleCategory(opt: MenuOption<SubCategory[]>): void {
    updateQuery(this, 'category', opt.key).catch(() => void 0);
    this.setDefaultSubCategory();
  }

  get subCategories(): SubCategory[] {
    const key = this.category;
    return this.categoryMenu.find(opt => opt.key === key)?.extra ?? [];
  }

  get subCategory(): string {
    return getQuery(this, 'subcategory', { toArray: false, toNumber: false }) ?? '';
  }
  get subCategoryMenu(): MenuOption<SubSubCategory[]>[] {
    return this.subCategories.map(cat => {
      return {
        key: cat.name,
        text: cat.name,
        extra: cat.subsubcategories,
      };
    });
  }

  handleSubCategory(opt: MenuOption<SubSubCategory[]>): void {
    updateQuery(this, 'subcategory', opt.key).catch(() => void 0);
  }

  get subSubCategory(): string {
    return getQuery(this, 'subsubcategory', { toArray: false, toNumber: false }) ?? '';
  }

  get subSubCategories(): SubSubCategory[] {
    const key = this.subCategory;
    return this.subCategoryMenu.find(opt => opt.key === key)?.extra ?? [];
  }

  @Watch('selectedBots', { immediate: true, deep: true })
  updateSubCategory(val: number[]): void {
    this.$emit('filter', val);
  }

  @Watch('subSubCategories', { immediate: false, deep: true })
  updateSubSubCategoryRoute(val: SubSubCategory[]): void {
    if (!val.find(sc => sc.name === this.subSubCategory)) {
      this.setDefaultSubSubCategory();
    }
  }

  setDefaultSubCategory(): void {
    this.handleSubCategory(this.subCategoryMenu[0]);
  }

  setDefaultSubSubCategory(): void {
    this.handleSubSubCategory(this.subSubCategoryMenu[0]);
  }

  get selectedBots(): number[] {
    return this.subSubCategoryMenu.find(cat => cat.key === this.subSubCategory)?.extra || [];
  }

  get subSubCategoryMenu(): MenuOption<number[]>[] {
    return this.subSubCategories
      .filter(cat => !!cat.logicalBotIds.length)
      .map(cat => {
        return {
          key: cat.name,
          text: cat.name,
          extra: cat.logicalBotIds,
        };
      });
  }

  handleSubSubCategory(opt: MenuOption<number[]>): void {
    updateQuery(this, 'subsubcategory', opt.key).catch(() => void 0);
  }

  created(): void {
    if (!this.$route.query?.category) {
      this.handleCategory(this.categoryMenu[0]);
    }
    if (!this.$route.query?.subcategory) {
      this.handleSubCategory(this.subCategoryMenu[0]);
    }
    if (!this.$route.query?.subsubcategory) {
      this.setDefaultSubSubCategory();
    }
  }
}
